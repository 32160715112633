@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');

.BrazilianButtLift {
  padding: 0 20px 20px 20px;

  @include large {
    padding: 0 0 40px 0;
  }

  @include extraLarge {
    padding: 0 40px 40px 40px;
  }
  > .info-container {
    width: 100%;

    @include large {
      width: 750px;
    }

    @include extraLarge {
      width: 100%;
    }
    
    > .main-content {
      > .title {
        font-family: $primaryFont;
        font-size: 32px;
        line-height: 30px;
        color: $title-color;
        margin: 30px 0 30px 0;
      
        @include medium {
          margin: 60px 0 60px 0;
          font-size: 38px;
        }
      }
      > .primary-container {
        display: grid;
        grid-template-rows: auto auto;
        width: auto;
        height: auto;
        margin-bottom: 40px;
  
        @include medium {
          display: grid;
          grid-template-columns: 336px 284px;
        }
  
        @include large {
          display: grid;
          grid-template-columns: 400px 390px;
        }
    
        > .left, .right {
          display: flex;
          flex-direction: row;
    
          > img {
            width: 50px;
            height: 50px;
            margin-right: 24px;
            margin-top: 5px;
  
            @include medium {
              width: 70px;
              height: 70px;
            }
          }
    
          > .details {
            > .title-details {
              font-family: $primaryFont;
              font-size: 18px;
              line-height: 30px;
  
              @include large {
                font-size: 22px;
              }
            }
    
            > .info-details {
              display: flex;
              flex-direction: row;
              color: $title-color;
    
              > .number {
                font-family: $secondaryFont;
                font-size: 34px;
                font-weight: 800;
  
                @include medium {
                  font-size: 35px;
                }
  
                @include large {
                  font-size: 42px;
                }
              }
            }
          }
        }
    
        > .left {
          .detail {
            font-family: $secondaryFont;
            font-size: 19px;
            font-weight: 800;
            margin: 16px 0 0 10px;
  
            @include medium {
              font-size: 18px;
            }
  
            @include large {
              font-size: 22px;
            }
          }
        }
      }
      
      > .bar-wrapper {
        > .title {
          font-family: $primaryFont;
          font-size: 20px;
          line-height: 30px; 
          margin-bottom: 20px;
          color: $title-color;
  
          @include medium {
            font-size: 22px;
          }
        }
    
        > .percentage-container {
          width: 260px;
          height: 40px;
          border: 3px solid $title-color;
          margin-bottom: 20px;
  
          @include medium {
            width: 600px;
          }
  
          @include large {
            width: 700px;
          }

          @include extraLarge {
            width: 800px;
          }
        }
      }
    
      > .bar-wrapper {
        > .percentage-container {
            p{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: $title-color;;
            padding-top: 5px;
            padding-right: 20px;
            font-family: $primaryFont;
            font-size: 18px;
            font-weight: 800;
          }

          .percentage-six {
            width: 156px;
            height: 34px;
            background-image: linear-gradient(to right, $gradient-color 1%, $secondary-color 99%);
  
            @include medium {
              width: 360px;
            }
  
            @include large {
              width: 420px;
            }

            @include extraLarge {
              width: 480px;
            }
          }
        }
      }
    
      > .secondary-container {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
  
        @include medium {
          display: grid;
          grid-template-columns: 300px 300px;
        }
  
        @include large {
          display: grid;
          grid-template-columns: 400px 400px;
        }
    
        > .content {
          padding: 0 40px 40px 0;
    
          > .title-content {
            font-family: $primaryFont;
            font-size: 24px;
            line-height: 30px;
            color: $title-color;
            margin-bottom: 14px;
            
            @include medium {
              margin-bottom: 20px;
              font-size: 26px;
            }
  
            @include large {
              font-size: 28px;
            }
          }
    
          > .info-content {
            font-family: $secondaryFont;
            font-size: 20px;
            line-height: 30px;
            font-weight: $black;
  
            @include medium {
              line-height: 34px;
            }
  
            @include large {
              font-size: 22px;
            }
          }
        }
      }
    }
  
    > .definition-container {
        
      > .title-definition {
          font-family: $primaryFont;
          font-size: 24px;
          line-height: 30px;
          color: $title-color;
          margin-bottom: 14px;
  
          @include medium {
            margin-bottom: 20px;
            font-size: 26px;
          }
  
          @include large {
            font-size: 28px;
          }
      }

      > .subtitle {
        font-family: $primaryFont;
        font-size: 22px;
        line-height: 30px;
        color: $title-color;
        margin: 10px 0;
        
        @include medium {
          font-size: 24px;
        }

        @include large {
          font-size: 26px;
        }
      }
  
      > .definition, .detail {
          font-family: $secondaryFont;
          font-size: 20px;
          line-height: 34px;
          font-weight: $black;
          
          @include large {
            font-size: 22px;
          }
      }
    }

    > .link-container {
      padding: 40px 0 ;
      display: flex;
      justify-content: flex-start;
      > .link{
        border: 4px solid $title-color;
        font-family: $primaryFont;
        text-transform: uppercase;
        width: 130px;
        font-size: 16px;
        padding: 10px;
      }
  
      > .link:hover{
        background-color: $title-color;
        color: $color-white;
        transition: all 400ms linear 0s;
      }
    }

    > .slider-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      @include small {
        width: 90%;
      }

      @include extraLarge {
        width: 500px;
      }

      > .slick-slider {
        position: relative;
        > .slick-list {
          min-width: 200px;
          width: 300px;
          height: auto;

          @include small {
            width: 460px;
          }

          @include medium {
            height: 400px;
            width: 600px;
          }

          @include large {
            height: 500px;
            width: 507px;
          }

          @include extraLarge {
            width: 500px;
          }

          > .slick-track {
            width: 100%;
            height: 100%;
          
            > .slick-slide {
              > div {
                display: flex;
                justify-content: center;
                align-content: center;
                height: 100%;

                > img {
                  width: 100%;
                  height: auto;
                  height: auto;
                  object-fit: contain;
                }

                > .slide-overlay {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  background-color: rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }

        > .prev-arrow {
          display: flex;
          justify-content: center;
          align-self: center;
          position: absolute;
          top: 50%;
          left: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;
          z-index: 2;

          @include large {
            left: -60px;
            border: 1px solid $color-black;
          }
          > svg {
            color: $color-black;
          }
        }

        > .next-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          right: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;

          @include large {
            right: -60px;
            border: 1px solid $color-black;
          }

          > svg {
            color: $color-black;
          }
        }
      }
    }
    }
}