@import "styles/Mixin.scss";
@import "styles/Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Welcome {
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > .title {
    color: $title-color;
    font-size: 35px;
    font-weight: 600;
    padding: 20px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-family: $primaryFont;

    &::after {
      content: "";
      width: 50px;
      height: 2px;
      background-color: $title-color;
      position: absolute;
      bottom: 7%;
      left: 44%;

      @include medium {
        bottom: 20%;
        left: 49%;
      }
    }
  }

  > .welcome-to {
    column-gap: 20px;
    display: grid;
    row-gap: 20px;
    width: 100%;

    @include large {
      grid-template-columns: 1fr 1fr;
    }

    > .info-container {
      align-items: center;
      border: 10px solid $secondary-color;
      display: flex;
      padding: 10px;

      @include medium {
        margin: 0 auto;
      }

      > .description {
        background-color: $title-color;
        height: auto;
        padding: 30px;

        > p {
          color: $color-white;
          font-family: $secondaryFont;
          font-size: 17px;
          font-weight: 800;
          margin-bottom: 15px;
        }

        > .button {
          border-radius: 4px;
          border: 1px solid $color-white;
          color: $color-white;
          cursor: pointer;
          font-family: $secondaryFont;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          padding: 5px;
          text-align: center;
          text-transform: capitalize;
          vertical-align: middle;

          @include small {
            font-size: 16px;
            letter-spacing: 2px;
            padding: 10px 25px;
            width: fit-content;
          }
        }
      }
    }

    > .img-container {
      > img {
        height: 300px;
        object-fit: cover;
        width: 100%;

        @include small {
          height: 500px;
        }

        @include large {
          height: 362px;
          object-fit: fill;
          width: 100%;
        }
      }
    }
  }
}
