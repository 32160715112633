@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap');

.InfoCard {
  width: 320px;
  
  @include small {
    width: 360px;
  }

  > .img-container {
    height: 251px;
    width: 100%;
    background-color: $color-grey2;

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  > .info-container {
    padding: 25px 15px 30px;
    text-align: center;
    background-color: $color-white;
    > .title {
      font-family: $primaryFont;
      padding-bottom: 15px;
      color: $title-color;
      font-size: 24px;
      font-weight: $semibold;
    }

    > .read-more {
      font-family: $primaryFont;
      height: 42px;
      border: 1px solid $color-grey2;
      padding: 10px 25px;
      font-size: 16px;
      color: $title-color;
      text-transform: uppercase;
      letter-spacing: 2px;
    }
  }
}
