@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Facilities {
  font-family: $primaryFont;

  > .main-container {
    background-color: $secondary-color;
    height: auto;
    width: 100%;

    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      > .title {
        font-size: 26px;
        color: $color-white;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        padding: 20px 0 5px 0;

        @include medium {
          font-size: 35px;
        }
      }

      > .separator {
        width: 80px;
        height: 4px;
        background-color: $color-white;
      }
    }

    > .facilities-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 50px 0;

      @include medium {
        flex-direction: row;
        padding: 100px 0;
      }

      > .facility {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        &:last-child {
          @include medium {
            margin-bottom: 0;
            margin-right: 0;
          }
        }

        @include medium {
          margin-right: 40px;
          margin-bottom: 0;
        }

        > .box {
          width: 300px;
          height: 195px;
          background-color: $color-grey2;

          @include medium {
            width: 200px;
            height: 130px;
          }

          @include large {
            width: 300px;
            height: 230px;
          }

          @include extraLarge {
            width: 350px;
          }
        }

        > .title {
          color: $color-white;
          margin-top: 20px;
          font-size: 24px;
          font-weight: $black;
          text-transform: capitalize;
          text-align: center;

          @include large {
            font-size: 28px;
          }

          @include extraLarge {
            font-size: 30px;
          }
        }
      }
    }
  }
}
