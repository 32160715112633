@import "styles/Variables.scss";
@import "styles/Mixin.scss";

.HomeSlider {
  > .slick-slider {
    position: relative;
    > .slick-list {
      height: 100%;

      @include medium {
        min-height: 37vh;
        max-height: 63vh;
      }

      @include large {
        min-height: 71vh;
        max-height: 86vh;
      }

      @include extraLarge {
        height: 90vh;
      }
      > .slick-track {
        > .slick-slide {
          > div {
            > .content {
              > div {
                font-family: $primaryFont;
                left: 18%;
                position: absolute;
                top: 18%;
                z-index: 2;

                @include small {
                  left: 10%;
                  top: 30%;
                  width: 450px;
                  z-index: 2;
                }

                > h1 {
                  color: $color-white;
                  font-size: 17px;
                  font-weight: 600;
                  letter-spacing: 0.9px;
                  margin-bottom: 10px;
                  text-transform: uppercase;

                  @include small {
                    font-size: 26px;
                    margin-bottom: 20px;
                  }

                  @include medium {
                    font-size: 46px;
                  }
                }
                > h3 {
                  background-color: $color-black;
                  color: $color-white;
                  font-size: 12px;
                  font-weight: $medium;
                  letter-spacing: 3px;
                  margin-bottom: 5px;
                  padding: 5px;
                  text-transform: uppercase;
                  width: 218px;

                  @include small {
                    font-size: 16px;
                    margin-bottom: 5px;
                  }

                  @include medium {
                    font-size: 18px;
                    width: 380px;
                    padding: 5px 15px;
                  }
                }
              }

              > img {
                width: 100%;
              }
            }
            > .slide-overlay {
              background-color: rgba(0, 0, 0, 0.2);
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              z-index: 1;
            }
          }
        }
      }
    }

    > .prev-arrow {
      background-color: rgba(255, 255, 255, 0.3);
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      box-sizing: border-box;
      height: 40px;
      left: 0;
      line-height: 10px;
      position: absolute;
      top: 45%;
      width: 25px;
      z-index: 2;

      @include small {
        top: 35%;
      }

      @include medium {
        height: 55px;
        top: 50%;
        width: 40px;
      }

      > svg {
        color: white;
      }

      &:hover {
        transition: ease 0.3s all;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    > .next-arrow {
      background-color: rgba(255, 255, 255, 0.3);
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
      box-sizing: border-box;
      height: 40px;
      line-height: 10px;
      position: absolute;
      right: 0;
      top: 45%;
      width: 25px;

      @include small {
        top: 35%;
      }

      @include medium {
        height: 55px;
        top: 50%;
        width: 40px;
      }

      > svg {
        color: white;
      }

      &:hover {
        transition: ease 0.3s all;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
