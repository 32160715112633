@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Facilities {
  @include wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      > .title {
        font-family: $primaryFont;
        font-size: 26px;
        color: $title-color;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        padding: 20px 0 5px 0;

        @include medium {
          font-size: 35px;
        }
      }

      > .separator {
        width: 80px;
        height: 4px;
        background-color: $title-color;
      }
    }

    > .accommodation,
    .clinic {
      > .subtitle {
        color: $title-color;
        font-family: $primaryFont;
        font-size: 24px;
        font-weight: $black;
        font-weight: 600;
        letter-spacing: 0.9px;
        padding: 20px 0 5px 0;
        text-transform: uppercase;

        @include medium {
          font-size: 30px;
        }
      }

      > .detail {
        font-family: $secondaryFont;
        font-size: 18px;
        font-weight: $black;
        letter-spacing: 0.34px;
        line-height: 28px;
        padding: 30px 0;

        @include medium {
          padding: 20px 0;
          font-size: 20px;
        }

        @include extraLarge {
          padding: 20px 0;
          font-size: 22px;
        }
      }

      > .img-accommodation-container {
        display: flex;
        flex-direction: column;

        @include large {
          display: grid;
          row-gap: 40px;
          margin: 40px 0;
        }

        .row-1,
        .row-3,
        .row-4 {
          display: flex;
          flex-direction: column;
          align-items: center;

          @include large {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 40px;
          }

          img {
            width: 310px;
            height: 250px;
            margin-bottom: 40px;
            object-fit: cover;

            @include medium {
              width: 500px;
              height: 300px;
            }

            @include large {
              margin-bottom: 0;
              width: 385px;
              height: 250px;
            }
          }
        }

        .row-2 {
          display: flex;
          flex-direction: column;
          align-items: center;

          @include large {
            display: grid;
            grid-template-columns: auto auto auto;
            align-items: center;
          }

          img {
            width: 310px;
            height: 250px;
            margin-bottom: 40px;
            object-fit: cover;

            @include medium {
              width: 500px;
              height: 300px;
            }

            @include large {
              margin-bottom: 0;
              width: 250px;
              height: 250px;
            }
          }
        }
      }

      > .img-clinic-container {
        display: flex;
        flex-direction: column;

        @include large {
          display: grid;
          row-gap: 40px;
          margin: 40px 0;
        }

        .row-1,
        .row-2,
        .row-3 {
          display: flex;
          flex-direction: column;
          align-items: center;

          @include large {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 40px;
          }

          img {
            width: 310px;
            height: 250px;
            margin-bottom: 40px;
            object-fit: cover;

            @include medium {
              width: 500px;
              height: 300px;
            }

            @include large {
              margin-bottom: 0;
              width: 385px;
              height: 250px;
            }
          }
        }
      }
    }
  }
}
