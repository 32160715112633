@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Banner {
  font-family: $primaryFont;

  > .banner-img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
    height: 260px;
    background-color: $color-grey2;
    font-size: 16px;
    background: url("https://cdn.primaverasurgical.com/images/banner.png")
      no-repeat scroll center center;
    background-size: cover;
    &::before {
      content: "";
      background: linear-gradient(
        to right,
        rgba(17, 24, 42, 0.85),
        rgba(0, 0, 0, 0)
      );
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 260px;
    }

    @include medium {
      font-size: 30px;
      padding-left: 130px;
    }

    > img {
      height: auto;
      max-width: 100%;
    }

    h3 {
      color: $color-white;
      z-index: 2;
    }
  }

  > .banner-path {
    display: flex;
    align-self: center;
    padding: 20px 0 20px 10px;
    border-bottom: 1px solid $color-grey4;
    background-color: $secondary-color;
    font-size: 16px;
    color: $color-black;

    @include medium {
      padding: 20px 0 20px 130px;
    }

    > svg {
      width: 20px;
      height: 20px;
      padding: 5px 5px 0 5px;
      color: $color-black;

      &:last-child {
        display: none;
      }
    }

    > a {
      color: $color-black;
    }

    > a:hover {
      color: $secondary-color;
      transition: all 400ms linear 0s;
    }
  }
}
