@import "styles/Variables.scss";
@import "styles/Mixin.scss";

.Services {
  color: $color-black;

  > .cards-container {
    display: grid;
    grid-template-columns: 330px;
    row-gap: 40px;
    justify-content: center;
    align-items: center;
    padding: 100px 0;

    @include medium {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      flex-wrap: wrap;
    }

    @include large {
      justify-content: center;
    }

    > .InfoCard {
      @include large {
        margin-right: 30px;
        margin-bottom: 30px;
      }
    }
  }
}
