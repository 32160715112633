@import "styles/Mixin.scss";

.Testimonials {
  > .testimonials-wrapper {
    display: grid;
    row-gap: 30px;
    justify-content: center;
    padding: 100px 15px;

    @include medium {
      grid-template-columns: auto auto;
      column-gap: 20px;
    }

    @include large {
      grid-template-columns: 450px 450px;
      column-gap: 40px;
      padding: 100px 0;
    }

    @include extraLarge {
      grid-template-columns: 570px 570px;
    }
  }
}
