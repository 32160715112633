@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');

.Body {
  @include wrapper {
    display: flex;
    flex-direction: column;

    @include large {
      display: flex;
    flex-direction: row;
    }
  }
}