@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.PrivacyPolicy {
  @include wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;

    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;
    
        > .title {
          font-family: $primaryFont;
          font-size: 26px;
          color: $title-color;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.9px;
          padding: 20px 0 5px 0;
    
          @include medium {
            font-size: 35px;
          }
        }
    
        > .separator {
          width: 80px;
          height: 4px;
          background-color: $title-color;
        }
    }

    > .info-container {
      > .detail {
        font-family: $secondaryFont;
        font-size: 18px;
        font-weight: $black;
        letter-spacing: 0.34px;
        line-height: 28px;
        padding: 30px 0;

        @include medium {
          padding: 20px 0;
          font-size: 20px;
        }

        @include extraLarge {
          padding: 20px 0;
          font-size: 22px;
        }
      }

      > .subtitle {
        color: $title-color;
        font-family: $primaryFont;
        font-size: 24px;
        font-weight: $black;
        line-height: 30px;
        margin: 14px 0;
        
        @include medium {
          margin: 20px 0 ;
          font-size: 26px;
        }

        @include large {
          font-size: 28px;
        }
      }
    }
}
}