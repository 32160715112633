@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.ViewGallery {
  width: 100%;
  position: relative;
  background-color: $secondary-color;
  background-image: url("https://cdn.primaverasurgical.com/images/viewGalleryBg.png");
  background-position: center;
  background-origin: content-box;
  background-repeat: no-repeat;
  background-size: cover;
  // padding-bottom: 75px;

  > .container {
    position: relative;
    > .title {
      font-family: $primaryFont;
      text-align: center;
      color: $color-white;
      text-transform: uppercase;
      font-size: 35px;
      font-weight: 600;
      padding: 20px;
      position: relative;

      &::after {
        content: "";
        width: 50px;
        height: 2px;
        background-color: $color-white;
        position: absolute;
        bottom: 15%;
        left: 49%;
      }
    }

    > .gallery-slider {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      // border: 2px solid red;
      padding-bottom: 75px;

      @include small {
        width: 90%;
      }

      @include extraLarge {
        width: 500px;
      }

      > .slick-slider {
        position: relative;
        > .slick-list {
          min-width: 200px;
          width: 300px;
          height: auto;

          @include small {
            width: 460px;
          }

          @include medium {
            height: 400px;
            width: 600px;
          }

          @include large {
            height: 500px;
            width: 507px;
          }

          @include extraLarge {
            width: 500px;
          }

          > .slick-track {
            width: 100%;
            height: 100%;

            > .slick-slide {
              > div {
                display: flex;
                justify-content: center;
                align-content: center;
                height: 100%;

                > img {
                  width: 100%;
                  height: auto;
                  height: auto;
                  object-fit: contain;
                }

                > .slide-overlay {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  background-color: rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }

        > .prev-arrow {
          display: flex;
          justify-content: center;
          align-self: center;
          position: absolute;
          top: 50%;
          left: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;
          z-index: 2;

          @include large {
            left: -60px;
            border: 1px solid $color-black;
          }
          > svg {
            color: $color-black;
          }
        }

        > .next-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          right: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;

          @include large {
            right: -60px;
            border: 1px solid $color-black;
          }

          > svg {
            color: $color-black;
          }
        }
      }
    }
  }
}
