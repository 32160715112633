@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap');

.LasikEye {

  @include wrapper {
    
    > .info-container {
      margin-bottom: 40px;
     > .main-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      > .title {
        font-family: $primaryFont;
        font-size: 32px;
        line-height: 30px;
        color: $title-color;
        margin: 30px 0 30px 0;
        text-transform: capitalize;
  
        @include medium {
          margin: 60px 0 60px 0;
          font-size: 38px;
        }
      }

      > .subtitle {
        font-family: $primaryFont;
        font-size: 22px;
        line-height: 30px;
        color: $title-color;
        margin: 20px 0;

        @include medium {
          font-size: 24px;
        }

        @include large {
          font-size: 28px;
        }
      }

      > .third-subtitle {
        font-family: $primaryFont;
        font-size: 18px;
        line-height: 30px;
        color: $color-black;
        text-decoration: underline;
        margin: 20px 0;

        @include medium {
          font-size: 20px;
        }

        @include large {
          font-size: 24px;
        }
      }

      > .detail {
        font-family: $secondaryFont;
        font-size: 16px;
        line-height: 34px;
        font-weight: $black;
        color: $color-black;
        margin-left: 20px;

        @include large {
          font-size: 20px;
        }
      }
     }

     > .link-container {
      padding: 40px 0;
      display: flex;
      justify-content: flex-start;
      > .link{
        border: 4px solid $title-color;
        font-family: $primaryFont;
        text-transform: uppercase;
        width: 130px;
        font-size: 16px;
        padding: 10px;
      }
  
      > .link:hover{
        background-color: $title-color;
        color: $color-white;
        transition: all 400ms linear 0s;
      }
    }

     > .slider-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      justify-content: center;

      @include small {
        width: 90%;
      }

      @include extraLarge {
        width: 500px;
      }

      > .slick-slider {
        position: relative;
        > .slick-list {
          min-width: 200px;
          width: 300px;
          height: auto;

          @include small {
            width: 460px;
          }

          @include medium {
            height: 400px;
            width: 600px;
          }

          @include large {
            height: 500px;
            width: 507px;
          }

          @include extraLarge {
            width: 500px;
          }

          > .slick-track {
            width: 100%;
            height: 100%;
          
            > .slick-slide {
              > div {
                display: flex;
                justify-content: center;
                align-content: center;
                height: 100%;

                > img {
                  width: 100%;
                  height: auto;
                  height: auto;
                  object-fit: contain;
                }

                > .slide-overlay {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  background-color: rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }

        > .prev-arrow {
          display: flex;
          justify-content: center;
          align-self: center;
          position: absolute;
          top: 50%;
          left: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;
          z-index: 2;

          @include large {
            left: -60px;
            border: 1px solid $color-black;
          }
          > svg {
            color: $color-black;
          }
        }

        > .next-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 50%;
          right: 0;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          border: 1px solid transparent;

          @include large {
            right: -60px;
            border: 1px solid $color-black;
          }

          > svg {
            color: $color-black;
          }
        }
      }
    }
    }
  }
}