@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap');

.ValueCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 30px 15px;
  margin-bottom: 50px;
  background-color: $color-white;
  color: $color-black;
  

  @include medium {
    width: 350px;
    min-height: 480px;
  }

  @include large {
    width: 300px;
    min-height: 500px;
  }
  
  @include extraLarge {
    min-height: 480px;
    width: 350px;
  }

  &:hover {
    transition: 0.3s;
    background-color: $color-grey4;
    cursor: pointer;
  }

  > .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    box-shadow: 0px 0px 10px 0px rgba(138, 135, 135, 0.5);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    > img {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
    }
  }

  > .info-container {
    text-align: center;
    
    > .title {
      font-family: $primaryFont;
      margin: 20px 0 10px 0;
      font-size: 22px;
      font-weight: 500;
      color: $title-color;
    }

    > .subtitle {
      font-family: $primaryFont;
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
      margin-bottom: 20px;
      color: $title-color;
    }

    > .description {
      font-family: $secondaryFont;
      font-size: 18px;
      font-weight: $black;
    }
}
}