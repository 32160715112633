@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Signika:wght@400;500;600;700&display=swap');

// Colors
$primary-color: #f5f3f3;
$secondary-color: #ffd1ba;
$gradient-color: #f8ddd1;
$title-color: #dca489;

$color-grey2: #b7b7b7;
$color-grey4: #e0e0e0;
$color-grey6: #e5e5e5;

$color-black: #000000;

$color-white: #ffffff;

// Font-Weights
$thin: 100;
$extralight: 200;
$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;

// Font Family
$primaryFont: 'Signika', sans-serif; //titulos y subtitulos
$secondaryFont: 'Open Sans Condensed', Arial; //  detalles info


//font-family: 'Fira Sans Condensed', sans-serif;
//font-family: 'Hind Madurai', sans-serif;

//@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:ital,wght@0,400;0,500;1,300;1,400&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@400;500;700&display=swap');