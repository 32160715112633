@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.WhyChooseUs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: $color-black;

  @include large {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-template-rows: auto;
    align-items: center;
  }

  > .image-container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 400px;

    @include medium {
      height: 600px;
    }

    @include large {
      height: 100%;
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  > .right-container {
    display: flex;
    flex-direction: column;
   padding-top: 40px;

    @include large {
      margin-left: 50px;
    }


    > .info-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include large {
        align-items: flex-start;
      }

      > .title {
        font-family: $primaryFont;
        font-weight: $black;
        font-size: 30px;
        font-weight: bold;
        text-transform: capitalize;
        color: $title-color;
        margin-bottom: 40px;

        @include large {
          font-size: 36px;
        }
      }

      > .info-box {
        width: 90%;
        height: auto;
        box-shadow: 0px 0px 10px 0px rgba(138, 135, 135, 0.5);
        background-color: $color-white;
        border-radius: 10px;
        margin: 0 0 30px 0;
        padding: 20px;

        @include large {
          width: 450px;
          height: auto;
        }

        @include extraLarge {
          width: 600px;
          height: auto;
        }

        > .title {
          font-family: $primaryFont;
          font-size: 16px;
          font-weight: 500;
          line-height: 1.1;
          color: $title-color;

          @include medium {
            font-size: 20px;
          }

          @include extraLarge {
            font-size: 22px;
          }
        }

        > .details {
          font-family: $secondaryFont;
          font-size: 17px;
          font-weight: $black;

          @include medium {
            font-size: 18px;
          }

          @include large {
            font-size: 15px;
          }

          @include extraLarge {
            font-size: 20px;
          }
        }
      }
    }
  }
}
