@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap');

.Tourism {
   .destinations-container {
    width: 100%;
    height: auto;
    > .title {
      font-family: $primaryFont;
      font-size: 32px;
      line-height: 30px;
      color: $title-color;
      margin: 30px;

      @include medium {
        margin: 50px;
        font-size: 40px;
        line-height: 40px;
      }

      @include extraLarge {
        margin: 80px 200px;
        font-size: 60px;
        line-height: 60px;
      }
    }

    > .images-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: auto;

    > .row-one, .row-two, .row-three, .row-four {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;

      > .one, .two {
        display: flex;
        flex-direction: column;

        @include medium{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          column-gap: 4px;
        }
        
      }

      .text {
        background-color: rgb(221, 221, 221);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px;
        box-sizing: border-box;
        margin-bottom: 4px;

      p {
        font-family: $primaryFont;
        font-size: 18px;
        line-height: 18px;
        color: $title-color;
        text-align: center;
  
        @include medium {
          font-size: 30px;
          line-height: 30px;
        }
  
        @include extraLarge {
          font-size: 40px;
          line-height: 40px;
        }
      }
     }

       img {
        width: 100%;
        height: 330px;
        object-fit: cover;
  
        @include extraLarge {
          height: 360px;
        }
      }
    }

    }

    > .extra-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0 20px;

      @include medium {
        margin: 0 40px 0 0;
      }

      > .disclaimer {
        display: flex;
        justify-content: flex-end;
        font-family: $secondaryFont;
        font-size: 16px;
        font-weight: 400;
        margin-top: 40px;
        font-weight: $black;
    
        @include medium {
          font-size: 20px;
        }
  
        @include extraLarge {
          font-size: 22px;
        }
      }

      > .link-container {
        padding: 40px 0;
        display: flex;
        justify-content: flex-start;
        > .link{
          border: 4px solid $title-color;
          font-family: $primaryFont;
          text-transform: uppercase;
          width: 130px;
          font-size: 16px;
          padding: 10px;
        }
    
        > .link:hover{
          background-color: $title-color;
          color: $color-white;
          transition: all 400ms linear 0s;
        }
      }
    }
  }
}