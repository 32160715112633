@import "styles/Mixin.scss";
@import "styles/Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Footer {
  width: 100%;
  background-color: $color-black;
  @include wrapper {
    @include medium {
      margin-top: 0;
    }

    > .top-footer {
      display: flex;
      flex-direction: column;

      @include medium {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        width: 100%;
      }

      @include large {
        display: grid;
        grid-template-columns: 1fr 1fr;
        height: 160px;
        width: 100%;
      }

      > .info-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;

        @include medium {
          justify-content: center;
        }

        > .icon-container {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background-color: $secondary-color;
          padding: 2px 0 0 0;
          align-items: center;
          justify-content: center;
          display: flex;
          color: $color-white;
          box-shadow: 0px -1px 14px 0px rgba(224, 144, 198, 0.55);
          @include medium {
            width: 50px;
            height: 50px;
          }

          > a {
            color: white;
          }
        }

        &.first,
        &.last {
          padding: 25px;
        }

        > .info-wrapper-description {
          margin-left: 20px;

          > .title-description {
            font-family: $primaryFont;
            font-weight: 600;
            text-transform: uppercase;

            @include medium {
              font-size: 18px;
            }

            @include extraLarge {
              font-size: 24px;
            }
          }

          > .description {
            font-family: $secondaryFont;
            font-size: 14px;
            padding-top: 10px;
            line-height: 22px;
            letter-spacing: 0.32px;

            @include medium {
              font-size: 16px;
            }

            @include extraLarge {
              font-size: 20px;
            }
          }
        }
      }
    }

    > .bottom-footer {
      background-color: black;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-bottom: 40px;

      @include large {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }

      > .map-section {
        display: flex;
        padding: 80px 0;

        @include large {
          padding: 50px;
        }

        > .map {
          background-color: beige;
          width: 320px;
          height: 320px;

          @include medium {
            width: 600px;
            height: 400px;
          }

          @include large {
            width: 300px;
            height: 300px;
          }

          @include extraLarge {
            width: 500px;
            height: 500px;
          }
        }
      }

      > .info-section {
        display: flex;
        padding: 10px 0;
        width: 100%;
        justify-content: space-around;

        @include small {
          width: 100%;
          padding-left: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
        }

        @include large {
          width: 100%;
          padding: 40px 0;
          justify-content: space-around;
        }

        @include extraLarge {
          justify-content: space-between;
        }

        > .quick-links,
        .our-services {
          padding-right: 0;

          > .title {
            color: white;
            text-transform: uppercase;
            font-family: $primaryFont;
            font-size: 16px;
            font-weight: 600;
            letter-spacing: -0.68px;

            @include large {
              font-size: 22px;
            }
          }

          > .separator {
            width: 60px;
            height: 2px;
            margin: 10px 0 0 0;
            background-color: $title-color;
          }
        }

        > .quick-links,
        .our-services {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          > .links-container {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            > li {
              a {
                padding-bottom: 20px;
                color: #c5c5c5;
                font-family: $secondaryFont;
                font-size: 16px;
                letter-spacing: 0.32px;
                transition: all 400ms linear 0s;

                @include large {
                  font-size: 20px;
                  padding-bottom: 20px;
                }
              }

              > .link:hover {
                color: $secondary-color;
              }

              > .sub-menu {
                > li {
                  > .sub-link {
                    padding-left: 30px;
                    &:hover {
                      color: $secondary-color;
                    }
                  }
                }
              }
            }
          }
        }

        > .our-services {
          .social-links {
            margin-top: 50px;
            display: flex;
            flex-direction: row;

            > .social-container {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;
              width: 36px;
              height: 36px;
              border: 1px solid #c5c5c5;
              border-radius: 70px;

              > a {
                color: #c5c5c5;
              }
            }
          }
        }
      }
    }
  }
}
