@import "styles/Mixin.scss";
@import "styles/Variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.AllInclusive {
  @include wrapper {
    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      > .subtitle {
        font-family: $primaryFont;
        font-size: 26px;
        color: $title-color;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        padding: 20px 0 5px 0;
        text-align: center;

        @include medium {
          font-size: 30px;
        }

        @include large {
          font-size: 35px;
        }
      }

      > .separator {
        width: 80px;
        height: 4px;
        background-color: $title-color;
      }
    }

    > .description {
      font-family: $secondaryFont;
      font-size: 18px;
      font-weight: $black;
      letter-spacing: 0.34px;
      line-height: 28px;
      padding: 30px 0;

      @include medium {
        padding: 20px 0;
        font-size: 20px;
      }

      @include extraLarge {
        padding: 20px 0;
        font-size: 22px;
      }
    }

    > .price {
      border: 3px dashed $title-color;
      width: 110px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 6px;
      font-family: $secondaryFont;
	    font-weight: $black;
      font-size: 18px;

      @include medium {
        font-size: 20px;
      }

      @include extraLarge {
        font-size: 22px;
      }
    }

    > .or {
      margin: 10px 0;
      font-family: $secondaryFont;
	  font-weight: $black;
      font-size: 18px;

      @include medium {
        font-size: 20px;
      }

      @include extraLarge {
        font-size: 22px;
      }
    }

    > .disclaimer {
      display: flex;
      justify-content: flex-end;
      font-family: $secondaryFont;
      font-size: 18px;
	  font-weight: $black;
      font-size: 18px;
      margin-bottom: 40px;

      @include medium {
        font-size: 20px;
      }

      @include extraLarge {
        font-size: 22px;
      }
    }

    > .link-container {
      padding: 0 0 40px 0;
      display: flex;
      justify-content: flex-end;
      > .link {
        border: 4px solid $title-color;
        font-family: $primaryFont;
        text-transform: uppercase;
        width: 130px;
        font-size: 16px;
        padding: 10px;
      }

      > .link:hover {
        background-color: $title-color;
        color: $color-white;
        transition: all 400ms linear 0s;
      }
    }

    > .img-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80%;
        margin-bottom: 50px;
      }
    }
  }
}
