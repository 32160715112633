@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');

.ServiceMenu {
  background-color: $secondary-color;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @include large {
    justify-content: flex-start;
    margin: 40px 20px;
  }

  ul {
    width: 300px;
    text-align: center;

    @include medium {
      width: 400px;
    }

    @include large {
      max-width: 180px;
    }

    li {
      list-style: none;
      padding: 20px;
      font-family: $primaryFont;
      text-transform: uppercase;
      cursor: pointer;
  }

  li:hover {
    background-color: $title-color;
    transition: all 400ms linear 0s;

    a {
      color: white;
    }
  }
}
}