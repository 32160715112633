@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Gallery {
  @include wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      > .title {
        font-family: $primaryFont;
        font-size: 26px;
        color: $title-color;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.9px;
        padding: 20px 0 5px 0;

        @include medium {
          font-size: 35px;
        }
      }

      > .separator {
        width: 80px;
        height: 4px;
        background-color: $title-color;
      }
    }

    > .buttons-container {
      width: 320px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: space-evenly;
      align-items: center;

      @include medium {
        width: 500px;
        grid-template-columns: repeat(3, auto);
        justify-content: space-around;
      }

      @include large {
        width: 700px;
        grid-template-columns: repeat(6, auto);
        justify-content: space-around;
      }

      > button {
        border: 2px solid $title-color;
        font-family: $primaryFont;
        text-transform: uppercase;
        width: 100px;
        padding: 10px;
        margin-bottom: 20px;
		font-weight: $black;
      }

      > button:hover {
        background-color: $title-color;
        color: $color-white;
        transition: all 400ms linear 0s;
      }
    }
  }

  > .gallery-slider {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;

    @include small {
      width: 90%;
    }

    @include extraLarge {
      width: 500px;
    }

    > .slick-slider {
      position: relative;
      > .slick-list {
        min-width: 200px;
        width: 300px;
        height: auto;

        @include small {
          width: 460px;
        }

        @include medium {
          height: 400px;
          width: 600px;
        }

        @include large {
          height: 500px;
          width: 507px;
        }

        @include extraLarge {
          width: 500px;
        }

        > .slick-track {
          width: 100%;
          height: 100%;

          > .slick-slide {
            > div {
              display: flex;
              justify-content: center;
              align-content: center;
              height: 100%;

              > img {
                width: 100%;
                height: auto;
                height: auto;
                object-fit: contain;
              }

              > .slide-overlay {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background-color: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }
      }

      > .prev-arrow {
        display: flex;
        justify-content: center;
        align-self: center;
        position: absolute;
        top: 50%;
        left: 0;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 1px solid transparent;
        z-index: 2;

        @include large {
          left: -60px;
          border: 1px solid $color-black;
        }
        > svg {
          color: $color-black;
        }
      }

      > .next-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        right: 0;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        border-radius: 50px;
        border: 1px solid transparent;

        @include large {
          right: -60px;
          border: 1px solid $color-black;
        }

        > svg {
          color: $color-black;
        }
      }
    }
  }
}
