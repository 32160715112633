@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.ContactUs {
  > .contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 160px;

    > .title-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 60px;

      > .title {
        color: $title-color;
        font-family: $primaryFont;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 0.9px;
        padding: 0 0 5px 0;
        text-transform: uppercase;

        @include medium {
          font-size: 35px;
        }
      }

      > .separator {
        width: 80px;
        height: 4px;
        background-color: $title-color;
      }
    }

    > .contact-container {
      align-self: center;
      column-gap: 10px;
      display: grid;
      width: min-content;

      @include medium {
        grid-template-columns: repeat(2, 1fr);
      }

      > .social-card {
        align-items: center;
        background-color: $color-white;
        border: 1px solid $color-grey6;
        box-sizing: border-box;
        display: flex;
        margin-bottom: 15px;
        padding: 28px 15px;
        width: 100%;

        @include medium {
          width: 380px;
          padding: 28px 30px;
        }

        @include large {
          width: 400px;
        }

        > .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          margin-right: 30px;
          border-radius: 50%;
          background-color: $secondary-color;

          > svg {
            color: $color-white;
          }
        }

        > .info {
          font-family: $secondaryFont;
          color: $color-black;
          font-size: 17px;
          font-weight: $black;
        }
      }
    }
  }
}
