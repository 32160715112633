@import "styles/Mixin.scss";
@import "styles/Variables.scss";


.AboutUs {
  > .section-one {
    display: flex;
    flex-direction: column;
    padding: 100px 15px;

    @include large {
      display: flex;
      flex-direction: row;
      justify-content: center;
      height: auto;
      width: 100%;
      padding: 100px 0;
    }

    @include extraLarge {
      margin: 0 auto;
    }

    > .column-left {
      width: 100%;

      @include large {
        width: 600px;
        margin: 0 60px;
      }

      @include extraLarge {
        width: 700px;
      }

      > .title-left {
        font-family: $primaryFont;
        font-weight: 500;
        font-size: 30px;
        text-transform: capitalize;
        color: $title-color;

        @include large {
          font-size: 36px;
        }
      }

      > .info {
        font-family: $secondaryFont;
        font-size: 18px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.34px;
        font-weight: $black;

        padding: 30px 0;

        @include medium {
          padding: 20px 0;
          font-size: 20px;
        }

        @include extraLarge {
          padding: 20px 0;
          font-size: 22px;
        }
      }
    }

    > .column-right {
      @include large {
        width: 300px;
      }

      @include extraLarge {
        width: 400px;
      }
      > .image {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;

        @include medium {
          width: 600px;
        }
        
        @include large {
          width: 100%;
        }

        > img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
      }
    }
  }

  > .section-two {
    @include large {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      max-height: 860px;
      width: 100%;
    }

    > .column-left {
      height: 300px;
      display: flex;
      justify-content: center;

      @include large {
        height: 860px;
      }

      > .image {
        height: 100%;
        > img {
          width: 320px;
          height: 100%;
          object-fit: cover;

          @include large {
            width: 100%;
          }
        }
      }
    }

    > .column-right {
      background-color: $primary-color;
      padding: 60px 60px 40px 60px;

      > .title-right {
        font-family: $primaryFont;
        font-size: 36px;
        font-weight: bold;
        text-transform: capitalize;
        color: $title-color;

        @include large {
          font-size: 30px;
        }

        @include extraLarge {
          font-size: 44px;
        }
      }

      > .subtitle {
        font-family: $primaryFont;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.3em;
        margin: 20px 0;
        color: $title-color;

        @include large {
          font-size: 22px;
        }

        @include extraLarge {
          font-size: 28px;
        }
      }

      > .info {
        font-family: $secondaryFont;
        font-size: 18px;
        letter-spacing: 0.34px;
        line-height: 28px;
        padding: 30px 0;
        font-weight: $black;

        @include medium {
          padding: 20px 0;
          font-size: 20px;
        }

        @include extraLarge {
          padding: 20px 0;
          font-size: 22px;
        }
      }
    }
  }

  > .section-three {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 0 55px;

    > .title {
      font-family: $primaryFont;
      font-size: 36px;
      font-weight: 600;
      text-transform: capitalize;
      padding-bottom: 50px;
      color: $title-color;

      @include large {
        font-size: 30px;
      }

      @include extraLarge {
        font-size: 44px;
      }
    }

    > .values-container {
      display: grid;
      row-gap: 40px;
      justify-content: center;
      align-items: center;

      @include medium {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
      }

      @include large {
        justify-content: center;
      }

      > .ValueCard {
        @include large {
          margin: 30px;
        }
      }
    }
  }
}
