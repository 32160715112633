@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url("https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap");

.Header {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $secondary-color;
  font-family: $primaryFont;

  &.home {
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;

    &.active {
      background-color: $secondary-color;
    }

    @include medium {
      left: 10%;
      width: 80%;
    }

    > .brand-logo {
      background-color: transparent;
      padding: 0;

      > a {
        > img {
          width: 65px;
          @include medium {
            width: 100%;
          }
        }
      }

      > .toggle-button {
        color: $color-white;
      }
    }

    > .navbar-links {
      background-color: $secondary-color;
      > ul {
        > li {
          @include medium {
            background-color: transparent;
            padding: 0 17px 0 0;
            &:last-child {
              padding-right: 0;
            }
          }

          @include large {
            padding: 0 28px 0 0;
          }

          @include extraLarge {
            padding: 0.75rem 0.75rem 0.75rem 0;
            &:last-child {
              padding-right: 3rem;
            }
          }
        }
      }

      @include medium {
        background-color: transparent;
      }

      a,
      button {
        color: $color-black;
      }
    }
  }

  &.active {
    > .navbar-links {
      display: block;
    }
  }

  @include medium {
    height: 100px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  > .brand-logo {
    padding: 0.5rem;
    background-color: $secondary-color;

    @include medium {
      width: 140px;
    }

    > a {
      > img {
        width: 50px;
        @include medium {
          width: 100%;
        }
      }
    }

    > .toggle-button {
      position: absolute;
      top: 1.5rem;
      right: 1rem;
      display: flex;
      color: $color-black;
      @include medium {
        display: none;
      }
    }
  }

  > .navbar-links {
    display: none;
    @include medium {
      display: flex;
    }
    > ul {
      display: flex;
      flex-direction: column;
      list-style: none;
      @include medium {
        flex-direction: row;
        justify-content: center;
      }
      li {
        border-bottom: 1px solid $title-color;

        @include medium {
          &:last-child {
            padding-right: 3rem;
          }
        }

        &:hover {
          > .sub-menu {
            display: contents;
            background-color: $secondary-color;
            position: absolute;
            z-index: 3;

            @include medium {
              display: block;
            }

            > ul {
              list-style: none;
              > li {
                padding: 0 0.5rem 0 0;
                > a {
                  color: $color-black;

                  &:hover {
                    color: $color-white;
                    transition: all 400ms linear 0s;
                  }
                }
              }
            }
          }
        }

        > .sub-menu {
          display: none;
        }

        @include medium {
          padding: 0.75rem;
          border-bottom: none;
          &:last-child {
            margin-right: 0;
          }
        }

        @include extraLarge {
          margin-right: 22px;
        }
        > a,
        > button {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          padding: 0.75rem 0 0.75rem 1.75rem;
          color: $color-black;
          font-weight: $semibold;
          text-transform: uppercase;
          font-size: 14px;

          &:hover {
            color: $color-white;
            transition: all 400ms linear 0s;
          }

          @include medium {
            color: $color-black;
            padding: 0;
            font-size: 14px;
          }

          @include large {
            padding: 0.75rem 0 0.75rem 0.75rem;
          }

          @include extraLarge {
            padding: 0.75rem 0 0.75rem 1.75rem;
            font-size: 14px;
          }
        }
      }
    }
  }
}
