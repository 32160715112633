@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600&display=swap');

.NonSurgicalProcedures {
  padding: 0 20px 20px 20px;

  @include large {
    padding: 0 0 40px 0;
  }

  @include extraLarge {
    padding: 0 40px 40px 40px;
  }
  > .info-container {
    width: 100%;

    @include large {
      width: 750px;
    }

    @include extraLarge {
      width: 100%;
    }
    > .title {
      font-family: $primaryFont;
      font-size: 32px;
      line-height: 30px;
      color: $title-color;
      margin: 30px 0 30px 0;
    
      @include medium {
        margin: 60px 0 60px 0;
        font-size: 38px;
      }
    }
  
   > .extra-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    > .detail {
      font-family: $secondaryFont;
      font-size: 16px;
      line-height: 34px;
      font-weight: $black;
        
       @include large {
        font-size: 20px;
      }
    }

  > .subtitle {
      font-family: $primaryFont;
      font-size: 22px;
      line-height: 30px;
      font-weight: 500;
      color: $title-color;
      margin: 20px 0 ;

      @include medium {
        font-size: 24px;
      }

      @include large {
        font-size: 28px;
      }
    }

  > .primary-list-container {
      font-family: $secondaryFont;
      font-size: 16px;
      line-height: 34px;
      font-weight: $black;
      margin-left: 20px;
      
      @include large {
        font-size: 20px;
      }
    }
   }

   > .link-container {
    padding: 40px 0 0 0;
    display: flex;
    justify-content: flex-start;
    > .link{
      border: 4px solid $title-color;
      font-family: $primaryFont;
      text-transform: uppercase;
      width: 130px;
      font-size: 16px;
      padding: 10px;
    }

    > .link:hover{
      background-color: $title-color;
      color: $color-white;
      transition: all 400ms linear 0s;
    }
  }
}
}
