@import "styles/Variables.scss";
@import "styles/Mixin.scss";

.ClientTestimonials {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $title-color;
  position: relative;

  @include large {
    padding: 20px 0;
    height: 732px;
    background-color: $color-white;

    &::after {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $title-color;
    }
  }

  @include wrapper {
    > .title {
      z-index: 2;
      position: relative;
      padding-bottom: 70px;
      text-transform: uppercase;
      text-align: justify;
      font-size: 35px;
      font-weight: 600;
      color: $primary-color;
      font-family: $primaryFont;
      @include small {
        padding-bottom: 40px;
      }
    }

    > .gallery-slider {
      width: 100%;
      height: 740px;
      position: relative;
      z-index: 3;

      @include medium {
        height: 640px;
      }

      @include large {
        width: 900px;
        height: 425px;
      }

      @include extraLarge {
        width: 950px;
        max-width: 1164px;
      }

      > .slick-slider {
        position: relative;
        > .slick-list {
          width: 100%;
          height: auto;
          @include medium {
            height: 590px;
            width: auto;
          }

          @include large {
            height: 422px;
          }

          > .slick-track {
            width: 100%;
            height: 100%;

            > .slick-slide {
              > div {
                height: 100%;

                > img {
                  width: 100%;
                  height: auto;
                }

                > .slide-overlay {
                  position: absolute;
                  left: 0;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  background-color: rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
        }

        > .prev-arrow {
          display: flex;
          justify-content: center;
          align-self: center;
          position: absolute;
          top: 0;
          right: 14%;
          box-sizing: border-box;
          width: 37px;
          height: 37px;
          border: 1px solid $color-white;
          z-index: 2;
          cursor: pointer;

          @include medium {
            top: 0;
            right: 8%;
          }

          @include large {
            top: -15%;
            right: 5%;
            border: 1px solid black;
          }

          > svg {
            color: $color-white;
            display: flex;
            margin: 0 auto;

            @include large {
              color: $color-black;
            }
          }
        }

        > .next-arrow {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;
          box-sizing: border-box;
          width: 37px;
          height: 37px;
          border: 1px solid $color-white;
          cursor: pointer;

          @include large {
            top: -15%;
            border: 1px solid $color-black;
          }

          > svg {
            color: $color-white;
            display: flex;
            margin: 0 auto;

            @include large {
              color: $color-black;
            }
          }
        }
      }
    }
  }
}
