@import "styles/Variables.scss";
@import "styles/Mixin.scss";
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:ital,wght@1,200;1,300;1,500&family=Open+Sans+Condensed:wght@300&display=swap');

.Face {
  @include wrapper {
    display: flex;
    flex-direction: column;

    @include large {
      display: flex;
    flex-direction: row;
    }
  }
}